export enum Pages {
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  ChangePassword = 'change-password',
  VerifyAccount = 'verify-account',
  JoinTeam = 'join-team',
  Profile = 'profile',
  Portfolio = 'portfolio',
  Funds = 'funds',
  Dashboard = 'dashboard',
  Investors = 'investors',
  Report = 'report',
  StartupDiscovery = 'startup-discovery',
  ScreeningCriteria = 'screening-criteria',
  ExploreAll = 'explore-all',
  InterestedStartup = 'interested-startup',
  Notification = 'notifications',
  Message = 'messages',
  Event = 'events',
  Network = 'networks',
  MyNetwork = 'myNetwork',
  AllPeople = 'all-people',
  Startups = 'startups',
  User = 'user',
  SearchStartups = 'search-startups',
  SearchPeople = 'search-people',
  TeamInfo = 'teaminfo',
  DealFlow = 'deal-flow',
  ScreenedStartups = 'screened-startups',
  DataRoom = 'data-room',
  Community = 'community',
  AllCommunities = 'all-communities',
  CommunityGroup = 'community-group',
  Explore = 'explore',
  Organisations = 'organisations',
  UsersRoles = 'users',
  Requests = 'requests',
  Group = 'group',
  FundManager = 'fund-manager',
  AccessDenied = 'access-denied',
  SomethingWentWrong = 'something-went-wrong',
}
