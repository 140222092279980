export const generateId = () => Math.random().toString(36).substring(2, 10)
export const capitalize = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()

export function ucword(str: string) {
  if (!str || typeof str !== 'string') return ''
  return str.split(' ').map(capitalize).join(' ')
}

export const addDataToQueryString = (
  url: string,
  data: Record<string, any>,
  reset?: boolean
) => {
  if (typeof url !== 'string') return url

  let urlParams = new URLSearchParams(url)

  for (let key in data) {
    if (Array.isArray(data[key])) {
      let oldValues = urlParams.get(key)
      let fullValues = []
      if (oldValues && !reset) {
        fullValues = oldValues.split(',').concat(data[key])

        urlParams.set(
          key,
          Array.from(new Set(fullValues.map((item) => item.toString()))).join(
            ','
          )
        )
      } else if (data[key].length > 0) {
        urlParams.set(key, data[key].join(','))
      } else {
        urlParams.delete(key)
      }
    } else if (
      data[key] !== '' &&
      data[key] !== undefined &&
      data[key] !== null
    ) {
      urlParams.set(key, data[key])
    } else urlParams.delete(key)
  }

  return decodeURIComponent(urlParams.toString())
}

export function pad(num: number | string, length: number) {
  var str = '' + num
  while (str.length < length) {
    str = '0' + str
  }
  return str
}

export const currencyFormat = (num: number | string) => {
  if (isNaN(Number(num))) return num
  return Number(num)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .replace(/(\.00)$/, '')
}

export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}
export const isValidEmail = (email: string) => {
  if (typeof email != 'string') return email
  return email.match(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
  )
}
export const isEmpty = (str: string) => {
  if (typeof str !== 'string') return !str
  return !str.trim()
}

export function isValidHttpUrl(str: string) {
  let url
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export function extractDomainFromUrl(str: string) {
  let url
  try {
    url = new URL(str)
  } catch (_) {
    return str
  }
  return url.hostname
}

export const getSize = (size: number) => {
  if (!size) return ''
  if (size > 1_000_000) {
    return Math.round(size / (1024 * 1024)) + ' MB'
  } else {
    return Math.round(size / 1024) + ' KB'
  }
}
export const getMoneySize = (size: number) => {
  if (!size) return ''
  size = Number(size)
  if (size > 1_000_000_000) {
    return size / (1000 * 1000 * 1000) + ' b'
  } else if (size > 1_000_000) {
    return size / (1000 * 1000) + ' m'
  } else if (size > 1_000) {
    return size / 1000 + ' k'
  } else return size
}

export function urlBase64ToUint8Array(base64String: string) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  var base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  var rawData = window.atob(base64)
  var outputArray = new Uint8Array(rawData.length)

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
