import { InvestmentRound } from '@/apis/fundApis'
import { LegalStructureType } from '@/apis/investorGroupApis'
import { array, object, string } from 'yup'

const format = /^[-@*_\.\w]*$/
const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/

export const personalInfoSchema = (requirePassword = true) =>
  object().shape({
    gender: string().notRequired().oneOf(['Male', 'Female']),
    first_name: string()
      .required('First name is a required field')
      .min(2, 'Input a valid first name'),
    last_name: string()
      .required('Last name is a required field')
      .min(2, 'Input a valid last name'),
    email: string().email().required(),
    phone_number: string()
      .trim()
      .min(12)
      .max(18)
      .required('Phone number is a required field')
      .matches(/^\+?[01-9\s]+$/, 'Phone number is not valid'),
    password: requirePassword
      ? string()
          .required('Password is required')
          .matches(
            passwordFormat,
            'Password must have 8 or more characters, at least one uppercase and lowercase letter, and one number.'
          )
          .test(
            'special-chars',
            'Password cannot contain special characters other than _ @ . -',
            function (value) {
              return format.test(value as unknown as string)
            }
          )
          .strict(true)
      : string().notRequired(),
    confirm_password: requirePassword
      ? string()
          .min(5)
          .required('Enter Password that matches')
          .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value
          })
      : string().notRequired(),
  })

const organisationBaseSchema = object({
  // category: string().oneOf(Object.values(CategoryType)).required(),
  name: string().required(),
  structure: string()
    .oneOf(Object.values(LegalStructureType))
    .required('legal Structure is required'),
  registration_id: string()
    .min(1)
    .max(100)
    .required('registration id is required'),
  address: string().required().min(3),
  email: string().required().email(),
  phone_number: string()
    .trim()
    .min(10)
    .max(18)
    .required('phone number is a required field')
    .matches(/^\+?[01-9\s]+$/, 'phone number is not valid'),
  website: string().url().notRequired(),
})
export const organisationSchema = organisationBaseSchema.shape({})
export const organisationEditSchema = organisationBaseSchema.shape({
  industry_focus: array()
    .of(string().trim().required())
    .min(1)
    .nullable()
    .notRequired(),
  investment_focus: array()
    .of(string().trim().oneOf(Object.keys(InvestmentRound)).required())
    .min(1)
    .nullable()
    .notRequired(),
})
