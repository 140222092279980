import { ToastNotify } from '@/common/toastManager'
type WithAsyncFn<T = unknown> = () => T | Promise<T>
type WithAsyncReturn<TData, TError> = Promise<{
  response: TData | null
  error: TError | unknown
}>
export async function withAsync<TData = unknown, TError = unknown>(
  fn: WithAsyncFn<TData>,
  finalFn?: () => void
): WithAsyncReturn<TData, TError> {
  try {
    if (typeof fn !== 'function')
      throw new Error('The first argument must be a function')

    if (!navigator.onLine) {
      ToastNotify('info', {
        title: 'No Network',
        content: 'Kindly ensure that, you have internet connectivity',
      })
      return {
        error: null,
        response: null,
      }
    }
    const response = await fn()

    if ((response as any)?.error) {
      return {
        error: (response as any).error,
        response: null,
      }
    }
    return {
      response,
      error: null,
    }
  } catch (error) {
    return {
      error,
      response: null,
    }
  } finally {
    finalFn?.()
  }
}

export const errorHandler = (error: any, cs?: string) => {
  ToastNotify('error', {
    message: Array.isArray((error as any).response?.data?.message)
      ? (error as any).response.data.message[0]
      : (error as any).response?.data?.message || cs || (error as any)?.message,
  })
}
