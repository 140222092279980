import SelectInput from '@/common/Input/SelectInput'

import { FormikHelpers, useFormik } from 'formik'

import Button from '@/common/button/Button'

import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import { organisationSchema } from '../validators/signup.validator'
import {
  CategoryType,
  InvestorGroupReqDTO,
  LegalStructureType,
} from '@/apis/investorGroupApis'
import { Storage } from '@/helpers/Storage'

interface Props {
  handleNext: () => void
}
const storedData = Storage.getItem('GROUP') || {}
const initialValues: InvestorGroupReqDTO = {
  category: (storedData.category || '') as CategoryType,
  name: storedData.name || '',
  structure: (storedData.structure || '') as LegalStructureType,
  registration_id: storedData.registration_id || '',
  address: storedData.address || '',
  email: storedData.email || '',
  phone_number: storedData.phone_number || '',
  website: storedData.website || '',
}
function OrgDetails({ handleNext }: Props) {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: submitHandler,
    validationSchema: organisationSchema,
  })

  async function submitHandler(
    values: InvestorGroupReqDTO,
    { setSubmitting, resetForm }: FormikHelpers<InvestorGroupReqDTO>
  ) {
    setSubmitting(false)
    Storage.setItem('GROUP', values)
    handleNext()
  }

  return (
    <form
      onSubmit={handleSubmit}
      className='flex flex-col gap-3'
      style={{ height: 'fit-content' }}
    >
      {/* <span className='flex flex-col gap-1'>
        <FormGroup id='category' label='Select Catgory' required>
          <SelectInput
            placeholder='Select Category'
            options={Object.values(CategoryType)}
            className='w-full'
            onChange={handleChange}
            onBlur={handleBlur}
            name='category'
            value={values.category}
            error={
              errors.category &&
              (touched.category as unknown as string) &&
              errors.category
            }
          />
        </FormGroup>
      </span> */}
      <span className='flex flex-col gap-1'>
        <FormGroup id='name' label='Organisation Name' required>
          <InputWithAdornment
            name='name'
            title='organisation name'
            value={values.name}
            onChange={handleChange}
            placeholder='Enter organisation name'
            error={
              !values.name
                ? errors.name &&
                  (touched.name as unknown as string) &&
                  errors.name
                : ''
            }
            onBlur={handleBlur}
          />
        </FormGroup>
      </span>
      <section className='grid grid-cols-1 gap-2 sm:grid-cols-2'>
        <FormGroup id='structure' label='Structure' required>
          <SelectInput
            placeholder='Select'
            autoCorrect='structure'
            options={Object.values(LegalStructureType).sort((a, b) =>
              a.localeCompare(b)
            )}
            className='w-full'
            onChange={handleChange}
            onBlur={handleBlur}
            name='structure'
            value={values.structure}
            menuClassName='!min-w-[240px]'
            error={
              errors.structure &&
              (touched.structure as unknown as string) &&
              errors.structure
            }
          />
        </FormGroup>
        <FormGroup id='registration_id' label='Registration ID Number' required>
          <InputWithAdornment
            name='registration_id'
            title='Registration ID Number'
            value={values.registration_id}
            onChange={handleChange}
            placeholder='Enter Reg ID'
            error={
              !values.registration_id
                ? errors.registration_id &&
                  (touched.registration_id as unknown as string) &&
                  errors.registration_id
                : ''
            }
            onBlur={handleBlur}
          />
        </FormGroup>
      </section>

      <FormGroup id='address' label='Physical Address' required>
        <InputWithAdornment
          name='address'
          title='Physical Address'
          value={values.address}
          onChange={handleChange}
          placeholder='Address'
          error={
            (errors.address &&
              (touched.address as unknown as string) &&
              errors.address) ||
            ''
          }
          onBlur={handleBlur}
        />
      </FormGroup>

      <section className='grid grid-cols-1 gap-2 sm:grid-cols-2'>
        <FormGroup id='email' label='Email Address' required>
          <InputWithAdornment
            name='email'
            title='Email Address'
            value={values.email}
            onChange={handleChange}
            type='email'
            placeholder='Enter email address'
            onBlur={handleBlur}
            error={
              (errors.email &&
                (touched.email as unknown as string) &&
                errors.email) ||
              ''
            }
          />
        </FormGroup>

        <FormGroup id='phone_number' label='Phone Number' required>
          <InputWithAdornment
            name='phone_number'
            title='Phone Number'
            value={values.phone_number}
            onChange={handleChange}
            placeholder='Enter phone number'
            error={
              (errors.phone_number &&
                (touched.phone_number as unknown as string) &&
                errors.phone_number) ||
              ''
            }
            onBlur={handleBlur}
          />
        </FormGroup>
      </section>
      <FormGroup id='website' label='Website' required>
        <InputWithAdornment
          name='website'
          title='Website'
          value={values.website}
          onChange={handleChange}
          placeholder='www.'
          left={
            <svg
              width='20'
              height='21'
              viewBox='0 0 20 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9.99984 18.9582C5.33431 18.9582 1.5415 15.1654 1.5415 10.4998C1.5415 5.83431 5.33431 2.0415 9.99984 2.0415C14.6654 2.0415 18.4582 5.83431 18.4582 10.4998C18.4582 15.1654 14.6654 18.9582 9.99984 18.9582ZM9.99984 2.2915C5.47369 2.2915 1.7915 5.97369 1.7915 10.4998C1.7915 15.026 5.47369 18.7082 9.99984 18.7082C14.526 18.7082 18.2082 15.026 18.2082 10.4998C18.2082 5.97369 14.526 2.2915 9.99984 2.2915Z'
                fill='#6D6D77'
                stroke='#6D6D77'
              />
              <path
                d='M7.5002 18.625H6.66686C6.3252 18.625 6.04186 18.3417 6.04186 18C6.04186 17.6583 6.30853 17.3833 6.6502 17.375C5.34186 12.9083 5.34186 8.09167 6.6502 3.625C6.30853 3.61667 6.04186 3.34167 6.04186 3C6.04186 2.65833 6.3252 2.375 6.66686 2.375H7.5002C7.7002 2.375 7.89186 2.475 8.00853 2.63333C8.1252 2.8 8.15853 3.00833 8.09186 3.2C6.5252 7.90833 6.5252 13.0917 8.09186 17.8083C8.15853 18 8.1252 18.2083 8.00853 18.375C7.89186 18.525 7.7002 18.625 7.5002 18.625Z'
                fill='#6D6D77'
              />
              <path
                d='M12.5 18.6252C12.4333 18.6252 12.3667 18.6169 12.3 18.5919C11.975 18.4836 11.7917 18.1252 11.9083 17.8002C13.475 13.0919 13.475 7.90856 11.9083 3.1919C11.8 2.8669 11.975 2.50856 12.3 2.40023C12.6333 2.2919 12.9833 2.4669 13.0917 2.7919C14.75 7.75856 14.75 13.2252 13.0917 18.1836C13.0083 18.4586 12.7583 18.6252 12.5 18.6252Z'
                fill='#6D6D77'
              />
              <path
                d='M10 14.8335C7.675 14.8335 5.35833 14.5085 3.125 13.8502C3.11667 14.1835 2.84167 14.4585 2.5 14.4585C2.15833 14.4585 1.875 14.1752 1.875 13.8335V13.0002C1.875 12.8002 1.975 12.6085 2.13333 12.4918C2.3 12.3752 2.50833 12.3418 2.7 12.4085C7.40833 13.9752 12.6 13.9752 17.3083 12.4085C17.5 12.3418 17.7083 12.3752 17.875 12.4918C18.0417 12.6085 18.1333 12.8002 18.1333 13.0002V13.8335C18.1333 14.1752 17.85 14.4585 17.5083 14.4585C17.1667 14.4585 16.8917 14.1918 16.8833 13.8502C14.6417 14.5085 12.325 14.8335 10 14.8335Z'
                fill='#292D32'
              />
              <path
                d='M17.5001 8.62497C17.4335 8.62497 17.3668 8.61663 17.3001 8.59163C12.5918 7.02497 7.40015 7.02497 2.69181 8.59163C2.35848 8.69997 2.00848 8.52497 1.90015 8.19997C1.80015 7.86663 1.97515 7.51663 2.30015 7.4083C7.26681 5.74997 12.7335 5.74997 17.6918 7.4083C18.0168 7.51663 18.2001 7.87497 18.0835 8.19997C18.0085 8.4583 17.7585 8.62497 17.5001 8.62497Z'
                fill='#292D32'
              />
            </svg>
          }
          error={
            (errors.website &&
              (touched.website as unknown as string) &&
              errors.website) ||
            ''
          }
          onBlur={handleBlur}
        />
      </FormGroup>

      <Button
        size='lg'
        type='submit'
        className='mt-10'
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
      >
        Proceed
      </Button>
    </form>
  )
}

export default OrgDetails
