/* eslint-disable react-hooks/exhaustive-deps */
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom'
import PublicRoutes from '@/routes/PublicRoutes'
import ProtectedRoutes from '@/routes/ProtectedRoutes'

import AuthenticationLayout from '@/layouts/auth'
import SuccessLayout from '@/layouts/success'
import ApplicationLayout from '@/layouts/app'
import SignUp from '@/pages/auth/SignUp'
import SignIn from '@/pages/auth/SignIn'
import ChangePassword from '@/pages/auth/ChangePassword'
import Setup from '@/pages/auth/Setup'
import { Pages } from './route.constant'
import VerifyAccount from '@/pages/auth/VerifyAccount'
import { lazy } from 'react'
import StartupDiscovery from '@/pages/startupdiscovery'
import ErrorLayout from '@/layouts/error'
import SelectAccount from '@/pages/auth/SelectAccount'
import AccessDenied from '@/pages/error/AccessDenied'
import SomethingWentWrong from '@/pages/error/SomethingWentWrong'
import NotFound from '@/pages/error/NotFound'

const Requests = lazy(() => import('@/pages/usersAndRoles/Requests'))
const AccountProfile = lazy(
  () => import('@/pages/usersAndRoles/AccountProfile')
)
const JoinTeam = lazy(() => import('@/pages/auth/JoinTeam'))

const UsersRoles = lazy(() => import('@/pages/usersAndRoles'))

const Funds = lazy(() => import('@/pages/fund'))
const LPFundDetails = lazy(() => import('@/pages/fund/FundDetails'))
const BusinessDetails = lazy(() => import('@/pages/fund/BusinessDetails'))

const Timeline = lazy(() => import('@/pages/theNetwork/Timeline'))
const ScreenedStartups = lazy(() => import('@/pages/dealFlow/ScreenedStartups'))
const MyNetwork = lazy(() => import('@/pages/theNetwork/MyNetwork'))
const Startups = lazy(() => import('@/pages/theNetwork/Startups'))
const SingleStartup = lazy(() => import('@/pages/theNetwork/SingleStartup'))
const SingleUser = lazy(() => import('@/pages/theNetwork/SingleUser'))

const TeamInfo = lazy(() => import('@/pages/theNetwork/TeamInfo'))

const DealFlow = lazy(() => import('@/pages/dealFlow'))
const DealFlowScreeningCriteria = lazy(
  () => import('@/pages/dealFlow/ScreeningCriteria')
)
const DataRoom = lazy(() => import('@/pages/dealFlow/dataRoom/index'))
const Folder = lazy(() => import('@/pages/dealFlow/dataRoom/Folder'))

const Communities = lazy(() => import('@/pages/community'))
const CommunityGroup = lazy(() => import('@/pages/community/Group'))
const AllGroups = lazy(() => import('@/pages/community/AllGroups'))
const Explore = lazy(() => import('@/pages/community/Explore'))
const SingleCommunity = lazy(() => import('@/pages/community/SingleCommunity'))

const DiscoveryFolder = lazy(
  () => import('@/pages/startupdiscovery/details/Folder')
)
const PortfolioDataRoom = lazy(() => import('@/pages/portfolio/dataRoom'))
const PortfolioFolder = lazy(() => import('@/pages/portfolio/dataRoom/Folder'))

const Profile = lazy(() => import('@/pages/profile'))

const ScreeningCriteria = lazy(
  () => import('@/pages/startupdiscovery/ScreeningCriteria')
)

const DiscoveryDetails = lazy(() => import('@/pages/startupdiscovery/details'))
const AllStartup = lazy(() => import('@/pages/startupdiscovery/AllStartup'))
const InterestedStartups = lazy(
  () => import('@/pages/startupdiscovery/InterestedStartups')
)
const TeamDetails = lazy(
  () => import('@/pages/startupdiscovery/details/TeamDetails')
)
const Messages = lazy(() => import('@/pages/message'))
const Notifications = lazy(() => import('@/pages/notification'))

const SingleGroup = lazy(() => import('@/pages/theNetwork/SingleGroup'))
const SingleFund = lazy(() => import('@/pages/theNetwork/SingleFund'))

const NetworkInvestors = lazy(() => import('@/pages/theNetwork/Investors'))
const GroupTeamMembers = lazy(
  () => import('@/pages/theNetwork/GroupTeamMembers')
)
const CatchAllAuthRoute = () => {
  return <Navigate to='/auth' />
}

const EventsPage = lazy(() => import('@/pages/events'))
const EventDetails = lazy(() => import('@/pages/events/EventDetails'))

const Portfolio = lazy(() => import('@/pages/portfolio'))
const PortfolioDetails = lazy(() => import('@/pages/portfolio/BusinessDetails'))

const RouteConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<SomethingWentWrong />}>
      <Route element={<PublicRoutes />}>
        <Route path='/auth' element={<AuthenticationLayout />}>
          <Route index element={<Navigate to={`/auth/${Pages.SignIn}`} />} />
          <Route path={Pages.SignIn} element={<SignIn />} />
          <Route path={Pages.SignUp} element={<SignUp />} />
          <Route path={Pages.ChangePassword} element={<ChangePassword />} />
          <Route path={Pages.VerifyAccount} element={<VerifyAccount />} />
          <Route path={Pages.JoinTeam} element={<JoinTeam />} />
        </Route>
        <Route path='*' element={<CatchAllAuthRoute />} />
      </Route>

      <Route element={<ProtectedRoutes />}>
        <Route path='/success' element={<AuthenticationLayout />}>
          <Route path={Pages.SignIn} element={<SelectAccount />} />
        </Route>
        <Route path='/success' element={<SuccessLayout />}>
          <Route index element={<Setup />} />
        </Route>
        <Route path='/app' element={<ApplicationLayout />}>
          <Route index element={<Navigate to={Pages.StartupDiscovery} />} />
          <Route path={Pages.StartupDiscovery}>
            <Route index element={<StartupDiscovery />} />
            <Route path={':startup'}>
              <Route index element={<DiscoveryDetails />} />
              <Route path='team-info' element={<TeamDetails />} />
              <Route path=':folderName' element={<DiscoveryFolder />} />
            </Route>
            <Route
              path={Pages.ScreeningCriteria}
              element={<ScreeningCriteria />}
            />
            <Route path={Pages.ExploreAll} element={<AllStartup />} />
            <Route
              path={Pages.InterestedStartup}
              element={<InterestedStartups />}
            />
          </Route>

          <Route path={Pages.Profile} element={<Profile />} />
          <Route path={Pages.UsersRoles}>
            <Route index element={<UsersRoles />} />
            <Route path={Pages.Requests} element={<Requests />} />
            <Route
              path={`${Pages.Requests}/:profile`}
              element={<AccountProfile />}
            />
          </Route>
          <Route path={Pages.Message} element={<Messages />} />
          <Route path={Pages.Notification} element={<Notifications />} />
          <Route path={Pages.Event}>
            <Route index element={<EventsPage />} />
            <Route path=':eventName' element={<EventDetails />} />
          </Route>
          <Route path={Pages.Portfolio}>
            <Route index element={<Portfolio />} />
            <Route path='Businesses'>
              <Route
                index
                element={
                  <Navigate to={`/app/${Pages.Portfolio}?tab=businesses`} />
                }
              />
              <Route path=':businessName' element={<PortfolioDetails />} />
            </Route>

            <Route path=':startupName'>
              <Route index element={<PortfolioDataRoom />} />
              <Route path=':folderName' element={<PortfolioFolder />} />
            </Route>
          </Route>
          <Route path={Pages.Network}>
            <Route index element={<Timeline />} />
            <Route path={Pages.MyNetwork} element={<MyNetwork />} />
            <Route path={Pages.AllPeople} element={<NetworkInvestors />} />

            <Route path={`${Pages.User}/:username`} element={<SingleUser />} />
            <Route path={`${Pages.Funds}/:fundname`} element={<SingleFund />} />

            <Route path={`${Pages.Group}/:groupname`}>
              <Route index element={<SingleGroup />} />
              <Route
                path={`${Pages.TeamInfo}`}
                element={<GroupTeamMembers />}
              />
            </Route>

            <Route path={Pages.Startups}>
              <Route index element={<Startups />} />
              <Route path=':startupName' element={<SingleStartup />} />
              <Route
                path={`:startupName/${Pages.TeamInfo}`}
                element={<TeamInfo />}
              />
            </Route>
          </Route>

          {/* funds */}
          <Route path={Pages.Funds}>
            <Route index element={<Funds />} />
            <Route path=':fundName'>
              <Route index element={<LPFundDetails />} />
              <Route path=':businessName' element={<BusinessDetails />} />
            </Route>
          </Route>

          <Route path={Pages.DealFlow}>
            <Route index element={<DealFlow />} />
            <Route
              path={`${Pages.ScreeningCriteria}`}
              element={<DealFlowScreeningCriteria />}
            />
            <Route
              path={`${Pages.ScreenedStartups}`}
              element={<ScreenedStartups />}
            />
            <Route path={`${Pages.DataRoom}/:startupName`}>
              <Route index element={<DataRoom />} />
              <Route element={<Folder />} path={':folderName'} />
            </Route>
          </Route>

          <Route path={Pages.Community}>
            <Route index element={<Communities />} />
            <Route
              path={`${Pages.Explore}/${Pages.AllCommunities}`}
              element={<Explore />}
            />
            <Route path={':communityName'}>
              <Route index element={<SingleCommunity />} />
              <Route path={Pages.CommunityGroup}>
                <Route index element={<AllGroups />} />
                <Route path={':groupName'} element={<CommunityGroup />} />
              </Route>
            </Route>
          </Route>
          <Route path={Pages.AccessDenied} element={<AccessDenied />} />
        </Route>
        <Route path='/error' element={<ErrorLayout />}>
          <Route path='not-found' element={<NotFound />} />
          <Route path='app-crash' element={<SomethingWentWrong />} />
        </Route>
      </Route>
      <Route path='*' element={<Navigate to='/error/not-found' />} />
    </Route>
  )
)

export default RouteConfig
