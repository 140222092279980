import { useCallback, useMemo, useState } from 'react'

import { contextFactory } from '../contexts/helpers/contextFactory'
import { AuthUser } from '@/apis/authApis'
import { Storage } from '@/helpers/Storage'
import { InvestorGroupResDTO } from '@/apis/investorGroupApis'
import { useQueryClient } from 'react-query'
import { flushSync } from 'react-dom'

export type Account =
  | ({ accountType: 'user' } & AuthUser)
  | ({ accountType: 'organisation' } & InvestorGroupResDTO)
type AuthValues = {
  getAccessToken: () => string
  getAuthState: () => Partial<AuthUser> | undefined
  currentAccount: Account
  isOrganisation: () => boolean
}
type AuthActions = {
  setAccessToken: (at: string) => void
  setAuthState: (authState: Partial<AuthUser>) => void
  logOut: () => void
  switchAccount: (account: Account) => void
}
const [useAuthContext, AuthContext] = contextFactory<AuthValues>()
const [useAuthActionsContext, AuthActionsContext] =
  contextFactory<AuthActions>()

export { useAuthContext, useAuthActionsContext }

interface Props {
  children: React.ReactNode
}

const currentAccount = Storage.getItem('SIFUSE_INVESTOR_CURRENT_ACCOUNT')
const userData = Storage.getItem('SIFUSE_INVESTOR_USER_DATA')
const permissions = Storage.getItem('SIFUSE_INVESTOR_USER_PERMISSIONS')

const AuthContextProvider = (props: Props) => {
  const { children } = props
  const queryClient = useQueryClient()
  const [authData, setAuthData] = useState<AuthUser>(
    userData
      ? {
          ...userData,
          permissions,
        }
      : undefined
  )
  const [currentAccountData, setCurrentAccountData] = useState<Account>(
    currentAccount
      ? currentAccount
      : {
          accountType: 'user',
          ...(userData
            ? {
                ...userData,
              }
            : {}),
        }
  )

  const getAccessToken = useCallback(() => {
    return authData?.access || ''
  }, [authData])
  const setAccessToken = useCallback(
    (at: string) =>
      setAuthData((prev) => ({ ...(prev ? prev : {}), access: at } as any)),
    []
  )
  const getAuthState = useCallback(() => authData, [authData])
  const isOrganisation = useCallback(
    () => currentAccountData.accountType === 'organisation',
    [currentAccountData.accountType]
  )

  const setAuthState = useCallback(
    (data: Partial<AuthUser>) => {
      setAuthData((prev) => {
        const userData = { ...(prev ? prev : {}), ...data } as any
        const { access, refresh, permissions, ...rest } = userData
        Storage.setItem('SIFUSE_INVESTOR_USER_DATA', rest)
        Storage.setItem('SIFUSE_INVESTOR_USER_PERMISSIONS', permissions)
        if (currentAccountData.accountType === 'user') {
          Storage.setItem('SIFUSE_INVESTOR_CURRENT_ACCOUNT', {
            accountType: 'user',
            ...rest,
          })
          setCurrentAccountData({ accountType: 'user', ...rest })
        }
        return userData
      })
    },
    [currentAccountData]
  )

  const logOut = useCallback(() => {
    setAuthData(undefined as any)
    Storage.removeItem('SIFUSE_INVESTOR_USER_DATA')
    Storage.removeItem('SIFUSE_INVESTOR_USER_PERMISSIONS')
    Storage.removeItem('SIFUSE_INVESTOR_CURRENT_ACCOUNT')
  }, [])

  const switchAccount = useCallback(
    (account: Account) => {
      flushSync(() => setCurrentAccountData(account))
      Storage.setItem('SIFUSE_INVESTOR_CURRENT_ACCOUNT', account)
      queryClient.resetQueries()
    },
    [queryClient]
  )
  const values = useMemo(() => {
    return {
      getAccessToken,
      getAuthState,
      currentAccount: currentAccountData,
      isOrganisation,
    }
  }, [getAccessToken, getAuthState, currentAccountData, isOrganisation])
  const actions = useMemo(
    () => ({
      setAccessToken,
      setAuthState,
      logOut,
      switchAccount,
    }),
    [setAccessToken, setAuthState, logOut, switchAccount]
  )
  return (
    <AuthContext.Provider value={values}>
      <AuthActionsContext.Provider value={actions}>
        {' '}
        {children}
      </AuthActionsContext.Provider>{' '}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
