import { PaginatedData, PaginationReq } from '@/apis/api.types'
import useDebounce from '@/hooks/useDebounce'
import clsx from 'clsx'
import {
  CSSProperties,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useInView } from 'react-hook-inview'
import { UseInfiniteQueryResult } from 'react-query'
import { BeatLoader } from 'react-spinners'

type Props<T extends object> = {
  getInfiniteData: (
    data: PaginationReq<T>
  ) => UseInfiniteQueryResult<PaginatedData<unknown>>
  data: PaginationReq<T>
  children: ReactNode | Function
  className?: string
  style?: CSSProperties
}
export type InfiniteScrollResult<T = any> = {
  isInitialLoad: boolean
  allPageData: T[]
}
const InfiniteScroll = forwardRef<InfiniteScrollResult, Props<any>>(
  (props, forwardedRef) => {
    const { getInfiniteData, data, children, className, style } = props
    const {
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isLoading,
      data: pagesPayload,
    } = getInfiniteData(data)
    const allData = useMemo(
      () =>
        pagesPayload?.pages
          ? pagesPayload?.pages.flatMap((res) =>
              Array.isArray(res?.results) ? res.results : []
            )
          : [],
      [pagesPayload]
    )

    const [loadMoreRef, inView] = useInView()
    useEffect(() => {
      if (!inView) return
      fetchNextPage()
    }, [inView, fetchNextPage])
    useImperativeHandle(
      forwardedRef,
      () => ({
        allPageData: allData,
        isInitialLoad: isLoading,
      }),
      [allData, isLoading]
    )
    return (
      <div style={style ? style : {}} className={clsx('w-full', className)}>
        {typeof children === 'function'
          ? children({ allPageData: allData, isInitialLoad: isLoading })
          : children}
        {hasNextPage && <div id='top' ref={loadMoreRef}></div>}
        {isFetchingNextPage && (
          <BeatLoader color='var(--mainprimary)' size={20} />
        )}
      </div>
    )
  }
)
InfiniteScroll.displayName = 'InfiniteScroll'

export const useInfiniteScrollForSelect = <
  D extends object,
  T extends object = {}
>(
  getInfiniteData: (
    data: PaginationReq<T>
  ) => UseInfiniteQueryResult<PaginatedData<D>>,
  data?: PaginationReq<T>
) => {
  const [Search, setSearch] = useState('')
  const [loadMoreRef, isInView] = useInView()
  const debouncedValue = useDebounce(Search, 400)
  const {
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    data: all,
    fetchNextPage,
  } = getInfiniteData({
    search: debouncedValue,
    ...((data ? data : {}) as any),
  })

  useEffect(() => {
    if (!isInView || !all) return
    fetchNextPage()
  }, [isInView, all, fetchNextPage])
  const allFlattened = Array.isArray(all?.pages)
    ? all?.pages.reduce((acc, cur) => acc.concat(cur.results), [] as D[])
    : ([] as D[])

  return {
    debouncedValue,
    setSearch,
    data: allFlattened,
    loadMoreRef,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
  }
}

export default InfiniteScroll
